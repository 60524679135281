// 
// _table.scss
// 

.table {
  th {
    font-weight: $font-weight-bold;
    background-color: $table-head-bg;
  }
  td {
    background-color: $table-head-bg;
  }

  th:first-child, td:first-child {
    position: sticky;
    left: 0;
    background-color: $table-head-bg;
    border-bottom: 1px solid $table-border-color;
  }

  .table-light {
    color: $table-head-bg;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-w-action-col {
  th {
    font-weight: $font-weight-bold;
    background-color: $table-head-bg;
  }
  td {
    background-color: $table-head-bg;
  }

  th:last-child, td:last-child {
    width: 20px;
    position: sticky;
    right: 0;
    text-align: end;
    background-color: $table-head-bg;
  }

  .table-light {
    color: $table-head-bg;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}


