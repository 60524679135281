//
// _helper.scss
//

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// Font weight help class

.fw-medium {
  font-weight: $fw-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

// Social

.social-list-item {
  height: 2.2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}

//
.social-platform-list-item {
  height: 2.6rem;
  width: 2.6rem;
  line-height: calc(2.6rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;
  margin-left: auto;
  margin-right: 0;

  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}

.integration-facebook-button {
  &:before {
    .facebook-icon {
      padding: 10px;
      font-size: 30px;
      position: absolute;
      align-content: center;
    }
  }

  padding: 20px;
  left: 3px;
  font-size: 16px;
  width: 250px;
  text-align: center;
  text-decoration: none;
  background: #1877F2;
  color: white;

  &:hover {
    opacity: 0.7;
  }
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// media

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

// alert

.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  }
}

.vertical-centered-div {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-centered-div-for-appt {
  margin: 0;
  position: absolute;
  -ms-transform: translateY(-50%);
  transform: translateY(-10%);
}

.page-content-centered-for-appt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;

  @media (min-width: 600px) {
    width: 50%;
    max-width: 600px;
    min-height: 50vh;
  }
}

.page-content-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;

  @media (min-width: 600px) {
    width: 50%;
    max-width: 600px;
    min-height: 50vh;
  }
}

.horizontal-centered-div {
  margin: 0;
  position: absolute;
  top: 90%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.horizontal-centered-logo {
  text-align: center;
  width: $navbar-brand-box-width;
  position: absolute;
  z-index: 0;
  background-image: url("../../../images/techLabLogo.png");
  height: 30%;
  display: block;
  left: 0;
  bottom: 0;
  opacity: 0.50;
  margin: 0;
}

.page-content-full {
  display: flex;
  background-color: white;
  margin-top: $header-height +15px;

  @media (min-width: 600px) {
    min-height: 50vh;
  }
}

.page-content-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: auto;
  margin-top: $header-height + 15px;

  @media (min-width: 600px) {
    min-height: 50vh;
  }
}

.overlay-viv {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}